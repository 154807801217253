import PropTypes from "prop-types"
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Button,} from "reactstrap";
import Moment from "moment";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";


import { setFilterDomains, setFilterVersionStatus, setRefreshCount, toogleDataCheck, setFilterDimensions, setFilterExtendedStatsDomains, setFilterIntervallo, setFilterDataDa, setFilterDataA, setFilterMese}  from "../../store/app/DataQualityStatus/DataQualitySlice";
import { setFilterProductTypes, setFilterStatusCount } from "../../store/app/DataRollCall/DataRollCallSlice";
import { setFilterProcessType, setFilterDQIssueTypes } from "../../store/app/ProcessRuns/ProcessRunsSlice";
import FilterMese from "./FilterMese";


const FilterSubmit = ( props ) => {
  const dispatch = useDispatch();

	let [searchParams, setSearchParams] = useSearchParams();
	const intervallo = useSelector( (state) => state.dataQualityReducer.filterIntervallo);
	const fromDate = useSelector( (state) => state.dataQualityReducer.filterDataDa );
	const toDate = useSelector( (state) => state.dataQualityReducer.filterDataA );
	const domains = useSelector( (state) => state.dataQualityReducer.filterDomains || []);
	const versionStatus = useSelector( (state) => state.dataQualityReducer.filterVersionStatus );
	const ProcessTypes = useSelector( (state) => state.processRunsReducer.filterProcessTypes );
	const rollCallStatusDomains = useSelector( (state) => state.dataRollCallReducer.filterStatusCountDomains );
	const productTypes = useSelector( (state) => state.dataRollCallReducer.filterProductTypes );
	const extendedStatsDomains = useSelector( (state) => state.dataQualityReducer.filterExtendedStatsDomains)
	const dimensions = useSelector( (state) => state.dataQualityReducer.filterDimensions)
	const mese = useSelector( (state) => state.dataQualityReducer.filterMese)

	const {text, onClick, ...restProps } = props;

	const refreshCount = useSelector(  (state) => state.dataQualityReducer.refreshCount   )

	const handleClick = ((event) => {
	
	
		if (intervallo == -1){
			searchParams.set('fromDate', Moment (fromDate).format ('YYYY-MM-DD HH:mm:ss'))
			searchParams.set('toDate', Moment (toDate).format ('YYYY-MM-DD HH:mm:ss'))
		}else{
			searchParams.delete('fromDate')
			searchParams.delete('toDate')
		}
		domains?.length > 0 ? searchParams.set('domains', JSON.stringify (domains)) : searchParams.delete('domains')
		versionStatus?.length > 0 ? searchParams.set('status', JSON.stringify (versionStatus)) :searchParams.delete('status')
		ProcessTypes?.length > 0 ?  searchParams.set('process', JSON.stringify (ProcessTypes)) : searchParams.delete('process')
		rollCallStatusDomains?.length > 0 ? searchParams.set('rollcallstatus', JSON.stringify (rollCallStatusDomains)) : searchParams.delete('rollcallstatus')
		productTypes?.length > 0 ? searchParams.set('producttypes', JSON.stringify (productTypes)) : searchParams.delete('producttypes')
		extendedStatsDomains?.length > 0 ? searchParams.set('extendedStatsDomains', JSON.stringify (extendedStatsDomains)) : searchParams.delete('extendedStatsDomains')
		dimensions?.length > 0 ? searchParams.set('dimensions', JSON.stringify (dimensions)) : searchParams.delete('dimensions')
		
		mese != 0 ? searchParams.set('mese', mese ) : searchParams.delete('mese')



		searchParams.set('interval', intervallo)
		
		

		// console.log ('handleClick::params', searchParams.toString())
		dispatch(setRefreshCount (refreshCount + 1))
		dispatch(toogleDataCheck(false))
		// dispatch(setRefreshData(true))
		setSearchParams(searchParams)
	})

	const handleReset = () => {

		const interval = searchParams.get('interval') ? searchParams.get('interval') : 24

		dispatch(setFilterIntervallo(interval))
		dispatch(setFilterDataDa(searchParams.get('fromDate') ? Moment (searchParams.get('fromDate')).toDate() : Moment().subtract(interval, 'hours').format ('YYYY-MM-DD HH:mm:ss')))
		dispatch(setFilterDataA(searchParams.get('toDate') ? Moment (searchParams.get('toDate')).toDate() : Moment().format ('YYYY-MM-DD HH:mm:ss')))
		dispatch(setFilterMese(searchParams.get('mese') ? JSON.parse(searchParams.get('mese')) : 0))

		dispatch(setFilterDomains(searchParams.get('domains') ? JSON.parse(searchParams.get('domains')) : []))
		dispatch(setFilterExtendedStatsDomains(searchParams.get('extendedStatsDomains') ? JSON.parse(searchParams.get('extendedStatsDomains')) : []))	
		dispatch(setFilterVersionStatus(searchParams.get('status') ? JSON.parse(searchParams.get('status')) : []))
		dispatch(setFilterDimensions(searchParams.get('dimensions') ? JSON.parse(searchParams.get('dimensions')) : []))

		dispatch(setFilterProcessType(searchParams.get('process') ? JSON.parse(searchParams.get('process')) : []))

		dispatch(setFilterStatusCount(searchParams.get('rollcallstatus') ? JSON.parse(searchParams.get('rollcallstatus')) : []))	
		dispatch(setFilterProductTypes(searchParams.get('producttypes') ? JSON.parse(searchParams.get('producttypes')) : []))	

		dispatch(toogleDataCheck(false));
	}

	
	return (
    <>
      <Button {...restProps} onClick={handleClick}>
        {text || "Filtra"}
      </Button>

	  <span style={{ margin: '0 8px' }}></span>
	  
      <Button
        outline
        color="primary "
        className="mt-3 px-2"
        title="Ripristina i filtri"
		onClick={handleReset}
      >
        <FeatherIcon icon="rotate-ccw" size={16} className="mx-1" />
      </Button>
    </>
  );
}

FilterSubmit.propTypes = {
  onClick: PropTypes.any,
  text: PropTypes.string
}



export default FilterSubmit
